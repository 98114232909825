/*

add to your env.d.ts or other type declarations file

```typescript
declare global {
  interface Window {
    ...
    dataLayer: any[];
    ga: (cmd: string, sub: string, ...extra: any) => void;
    ...
  }
}
```

*/
import { watch } from "vue";
import { type Router } from "vue-router/types/router";

export function loadGA(key: string, router: Router): HTMLScriptElement {
  const script = document.createElement("script");

  // Global site tag (gtag.js) - Google Analytics
  window.dataLayer = window.dataLayer || [];
  function gtag(...args) {
    window.dataLayer.push(args);
  }
  gtag("js", new Date());
  gtag("config", "G-X15VNWNYEE");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${key}`;
  document.body.appendChild(script);

  trackPageLoads(router);
  return script;
}

export function trackPageLoads(router: Router): void {
  watch(router.currentRoute, (to) => {
    console.info(
      `%c[navigation - ${to.path}]`,
      "background: #eee; color: darkblue; padding: 2px; border-radius: 4px;",
    );
    if (window.ga) {
      ga("set", "page", `/shop/cart/#${to.path}`);
      ga("send", "pageview");
    }
  });
}
