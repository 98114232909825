import {
  createRouter as _createRouter,
  createMemoryHistory,
  createWebHistory,
} from "vue-router";

import { useUserStore } from "@/stores/user";
import { useSiteLockStore } from "@/utils/site_lock";
import debug from "debug";
import type { Router } from "vue-router";
// @ts-ignore
import { routes } from "vue-router/auto-routes";

const ROOT = import.meta.env.VITE_SITE_BETA ? "/beta/" : "/";
const log = debug("routes");

export function createRouter() {
  showRoutes(routes);
  const router: Router = _createRouter({
    // history: createMemoryHistory(ROOT)
    // history: createWebHistory(import.meta.env.BASE_URL),
    history: import.meta.env.SSR
      ? createMemoryHistory(ROOT)
      : createWebHistory(ROOT),
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    },
  });
  router.beforeEach((to, from) => {
    const sitelock = useSiteLockStore();
    const user = useUserStore();
    if (to.meta.requiresSignin && !user.isAuthenticated) {
      console.log("signin required");
      return { name: "accounts-signin", query: { path: from.path } };
    }
    if (to.meta.requiresRep && !user.isRep) {
      console.log("rep signin required");
      return { name: "accounts-profile" };
    }
    if (to.meta.requiresTester && !user.isTester) {
      console.log("tester signin required");
      return { name: "accounts-profile" };
    }
    if (
      to.meta.requiresBeta &&
      !sitelock.beta &&
      !/localhost:/.test(location.host)
    ) {
      console.log("page nnot found");
      return "/error/404/";
    }
    if (to.meta.releaseDate) {
      const release = new Date(to.meta.releaseDate as string);
      if (new Date() <= release) {
        return to.meta.beforeReleaseUrl || "/";
      }
    }
    if (to.meta.releaseDates) {
      const release = earliestReleaseDate(
        to.meta.releaseDates as Record<string, string>,
      );
      if (release && new Date() <= release) {
        return to.meta.beforeReleaseUrl || "/";
      }
    }
    if (sitelock.beta && !sitelock.active) {
      const isTester = user.isTester;
      if (!isTester && to.name !== "accounts-signin") {
        console.log("forcing back to signin", JSON.stringify(user.props));
        if (to.name !== "accounts-signin") {
          return { name: "accounts-signin", query: { next: to.path } };
        }
        return { name: "accounts-signin" };
      }
    }
  });

  // when a module can't be found
  // it could be because the build hasn't been copied to static yet
  // or has been deleted because it's too old
  router.onError((error, to) => {
    if (
      error.message.includes("Failed to fetch dynamically imported module") ||
      error.message.includes("Importing a module script failed")
    ) {
      console.error("detected incomplete build");
      // @ts-ignore
      window.location = to.fullPath;
    }
  });
  return router;
}

function showRoutes(routes: any[], base = "") {
  for (const route of routes) {
    // log("-", base + route.path, route.name);
    if (route.children) {
      showRoutes(route.children, route.path + "/");
    }
    route.props = true;
  }
}

function earliestReleaseDate(dates: Record<string, string>): Date | null {
  const user = useUserStore();
  let retval: Date | null = null;

  for (const groupName in dates) {
    if (["all"].includes(groupName) || user.groups.includes(groupName)) {
      const date = new Date(dates[groupName]);
      if (!retval || retval > date) {
        console.log(groupName, date);
        retval = date;
      }
    }
  }

  return retval;
}
